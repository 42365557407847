<template>
    <modal :clickToClose="true" name="taskCrud-modal" height="auto" width="600px" :pivotY="0.1">
    <div class="ui container p-1">
        <div class="ui form">
            <div class="field">
                <label>Date de réalisation</label>
                <VueCtkDateTimePicker locale="fr" v-model="deadline" :noLabel="true" buttonNowTranslation="Maintenant"/>
            </div>

            <div class="field">
                <label>Date d'éxecution</label>
                <input type="number" v-model="timer" />
            </div>

            <div class="action-buttons" style="text-align: right;">
                <button class="ui button" @click="$modal.hide('taskCrud-modal')">Annuler</button>
                <button class="ui blue button" @click="save">
                    Enregistrer
<!--                    <i class="right upload icon"></i>-->
                </button>
            </div>

        </div>
    </div>
    </modal>
</template>

<script>
    import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
    export default {
        name: "taskCrud",
        props:{
            current_task: {
                type: Object,
                default(){
                    return {}
                }
            }
        },
        data(){
          return{
              deadline: null,
              timer: 0
          }
        },
        methods:{
            save(){
                const deadline = new Date(this.deadline).getTime();
                const deadline_warn = (deadline - 3600000); // 1H in milliseconds
                const newTask = {
                    deadline: deadline,
                    deadline_warn: deadline_warn,
                    timer: this.timer
                };
                const data = {
                    collection: this.$SHARED.collections.documents,
                    query: {_id: this.current_task._id},
                    update: {'$set': newTask}
                };
                this.$store.requestUpdate(data).then(() => {
                    this.$modal.hide('taskCrud-modal');
                    this.$notify({
                        group: 'user-message',
                        type: 'success', // warn , error, success, info
                        text: this.$SHARED.messages.document.update_succeeded
                    });
                }, code => {
                    this.$notify({
                        group: 'user-message',
                        type: 'error', // warn , error, success, info
                        text: this.$SHARED.messages.technical_error
                    });
                });
            },
        },
    }
</script>

<style scoped>
    .border-bottom{
        border-bottom: 2px solid #767676;
    }
    .datetimepicker{
        z-index: 99999 !important;
    }
</style>